.ag-theme-alpine {
  --ag-header-background-color: #023059;
  --ag-header-foreground-color: #ffffff;
  --ag-header-color: #ffffff;
  --ag-font-size: 14px;
  --ag-font-family: 'Montserrat', 'Open Sans';
  --ag-header-cell-text-overflow: none;
  --ag-even-row-background-color: #f3f4f6;
  --ag-header-font-weight: bold;
}

.ag-theme-alpine .ag-row-even {
  background-color: var(--ag-even-row-background-color);
}

.ag-theme-alpine .ag-header-cell {
  font-size: 14px;
  font-weight: 'bold';
}

.ag-theme-alpine-dark {
  --ag-header-background-color: #023059;
  --ag-header-foreground-color: #ffffff;
  --ag-header-color: #ffffff;
  --ag-font-size: 14px;
  --ag-font-family: 'Montserrat', 'Open Sans';
  --ag-header-cell-text-overflow: none;
  --ag-even-row-background-color: #f3f4f6;
  --ag-header-font-weight: bold;
  --ag-background-color: #111728;
}

.ag-theme-alpine-dark .ag-row-even {
  background-color: var('#374151');
}

.ag-theme-alpine-dark .ag-header-cell {
  font-size: 14px;
  font-weight: 'bold';
}

.ag-theme-alpine .ag-row.ag-row-selected {
  background-color: #d0f0c0 !important; /* Light green background for selected rows */
}
