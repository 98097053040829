/* src/components/LoadingScreen/LoadingScreen.css */

.loading-screen {
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #152642;
  z-index: 9999;
  opacity: 0.9;
}

.loading-screen-small {
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: center;
  align-items: center;
}

.loading-icon {
  width: 100px;
  height: 100px;
  animation: spin 4s cubic-bezier(0.2, 0.8, 0.4, 1) infinite; /* Adjusted animation */
}

.loading-text {
  margin-top: 20px; /* Space between icon and text */
  font-size: 18px;
  color: #ffffff; /* White color for visibility against dark background */
  font-family: Arial, sans-serif;
  animation: fade 4s infinite; /* Optional animation for text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg); /* Spins fast up to 360 degrees */
    animation-timing-function: ease-out; /* Slows down after the fast spin */
  }
  80% {
    transform: rotate(360deg); /* Pauses at the full rotation */
  }
  100% {
    transform: rotate(360deg); /* Completes the stop */
  }
}

/* Optional fade effect for the loading text */
@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
