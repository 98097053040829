.overview-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children to the left */
  width: 100%;
  padding-left: 20px;
  padding-top: 20px; /* Adjust this as needed to match the timeline's padding */
}

/* Ensure the header has no additional padding or margin that disrupts alignment */
h3 {
  margin: 0;
  padding: 0;
}

.timeline-container {
  display: flex;
  justify-content: space-between; /* Evenly space the month labels */
  background-color: rgb(255, 255, 255);
  position: relative;
  height: 60px; /* Increase the height to accommodate circles and lines */
  border: 2px solid rgb(10, 10, 63);
  border-radius: 10px;
  margin: 20px;
}

.timeline-container-dark {
  display: flex;
  justify-content: space-between; /* Evenly space the month labels */
  background-color: #172a46;
  position: relative;
  height: 60px; /* Increase the height to accommodate circles and lines */
  border: 2px solid rgb(10, 10, 63);
  border-radius: 10px;
  margin: 20px;
}

.timeline-item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: flex-end;

  /* Align month labels at the bottom */
}

.circle {
  position: absolute;
  top: 10px; /* Position circles at the top */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
}

.circle-light {
  background-color: #09182d;
  border: 2px solid #09182d;
  color: white;
}

.circle-dark {
  background-color: white;
  border: 2px solid white;
  color: #09182d;
}

.line {
  position: absolute;
  top: 22px; /* Start below the circle */
  bottom: 20px; /* Reach above the month label */
  width: 2px;
  background-color: rgb(10, 10, 63); /* Match the circle border color */
  left: 50%; /* Center the line below the circle */
}

.line-dark {
  position: absolute;
  top: 22px; /* Start below the circle */
  bottom: 23px; /* Reach above the month label */
  width: 2px;
  background-color: grey; /* Match the circle border color */
  left: 50%; /* Center the line below the circle */
}

.label {
  background-color: #09182d; /* Dark blue background */
  color: lightgray;
  width: 100%; /* Full width to span the container */
  font-size: 18px; /* Larger font for visibility */
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: flex-end;
}
