.tooltip-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9rem;
  width: 100%;
}

.apexcharts-custom-tooltip {
  background: #2e3b4e;
  color: #ffffff;
  padding: 12px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 200px;
}
