.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 4px solid #023059;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid #023059;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  color: #1049e5;
  border-radius: 5px 5px 0 0;
  width: auto;
  height: 19px;
  font: normal normal normal 14px/19px Open Sans;
}

.react-tabs__tab--selected {
  background: #023059;
  border-color: #023059;
  border-bottom: 4px solid #023059;

  color: white;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
