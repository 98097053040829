.vehicle-grid .custom-header {
  background-color: #e7e7e7;
  color: rgb(0, 0, 0);
  font-weight: normal;
  height: auto;
}
.vehicle-grid .custom-header:hover {
  background-color: #e7e7e7;
  color: rgb(0, 0, 0);
  font-weight: normal;
}

.vehicle-grid .ag-header-cell-label {
  white-space: wrap;
  height: auto;
}

.vehicle-grid .ag-header-viewport {
  background-color: #e7e7e7;
  border: none;
}
