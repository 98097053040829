.custom-tooltip {
  width: 100%;
  height: auto;
  border-radius: 5%;
  padding: 2%;
  margin-top: -25%;
}

.custom-tooltip p {
  margin: 5px;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
