/* Custom styles for the DatePicker component */
.custom-datepicker {
  width: 140px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin-bottom: 7px;
  background-color: #162a46;
  color: white;
}

.custom-datepicker-light {
  width: 140px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin-bottom: 7px;
  /* background-color: #d0d5dd; */
  color: black;
}

.react-datepicker__calendar-icon {
  display: none;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 5px !important;
  font-size: 11px;
  width: 120px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}