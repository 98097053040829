/* Custom styles for the DatePicker component */
.custom-datepicker-prod {
  width: 150px;
  height: 18px;
  padding: 2px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #162a46;
  color: white;
}

/* Custom styles for the DatePicker component */
.custom-datepicker-prod-light-theme {
  width: 150px;
  height: 18px;
  padding: 2px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #ffffff;
  color: black;
}

.disabledDark {
  width: 200px;
  height: 25px;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #0a192d;
  background-color: #162a46;
  color: #2f445d;
}

.disabledLight {
  width: 200px;
  height: 25px;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #bec0c2;
  background-color: #ffffff;
  color: rgb(221, 221, 221);
}

/* Custom styles for the DateTimePicker component */
.custom-datetimepicker-prod {
  width: 150px;
  height: 12px;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #162a46;
  color: white;
}

/* Custom styles for the DateTimePicker component */
.custom-datetimeicker-prod-light-theme {
  width: 150px;
  height: 12px;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #ffffff;
  color: black;
}
