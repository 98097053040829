/* Custom styles for the DatePicker component */
.custom-datepicker-prod {
  width: 150px !important;
  height: 18px;
  padding: 2px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #162a46;
  color: white;
}

/* Custom styles for the DatePicker component */
.custom-datepicker-prod-light-theme {
  width: 150px !important;
  height: 18px;
  padding: 2px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #ffffff;
  color: black;
}

/* Custom styles for the DateTimePicker component */
.custom-datetimepicker-prod {
  width: 150px !important;
  height: 12px;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #162a46;
  color: white;
}

/* Custom styles for the DateTimePicker component */
.custom-datetimeicker-prod-light-theme {
  width: 150px !important;
  height: 12px;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 2px;
  border: 1px solid #2f445d;
  background-color: #ffffff;
  color: black;
}
